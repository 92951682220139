@import '../../../theme/scss/mixins.scss';
@import '../../../theme/scss/variables.scss';

.main_container {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 24px;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  &>svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }

  &__status__waiting {
    @include subtext2;
    padding: 4px 12px;
    background-color: #FFFBE4;
    color: #B19607;
  }

  &__status__active {
    @include subtext2;
    padding: 4px 12px;
    background-color: #F8FFEB;
    color: #3B8400;
  }
}

.second_block {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.second_header {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__hint>span {
    @include body6;
    color: var(--text-secondary)
  }

  &__sorting {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__search {
      display: flex;
      gap: 24px;
      align-items: flex-end;
      margin-bottom: 10px;

      &__container {
        width: 100%;
        box-sizing: border-box;
        height: 40px;
        background-color: var(--base-white);
        border-radius: 8px;
        border: 1px solid var(--base-50);
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 10px;

        &__text {
          @include body6;
          color: var(--text-secondary);
        }
      }
    }
  }

  &__filters {
    display: flex;
    gap: 16px;

    &__item {
      @include body6;
      width: 33%;
      border: 1px solid var(--btn-violet-secondary);
      border-radius: 8px;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--base-white);
      cursor: pointer;
    }
  }
}

.second_content_block {
  border-radius: 12px;
  background-color: var(--base-white);
  height: 74%;
  max-height: 74%;
  overflow-y: scroll;
}

.second_content_block::-webkit-scrollbar {
  display: none;
}

.therapist_block {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.therapist_card {
  width: 100%;
  padding: 16px;
  border-bottom: 1px solid var(--btn-violet-secondary);
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__left {
    display: flex;
    gap: 16px;
  }

  &__initials {
    @include body2;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    background-color: var(--btn-violet-secondary);
    color: var(--btn-violet-primary);
    display: flex;
    align-items: center;
    justify-content: center;

    &>img {
      width: 100%;
      object-fit: fill;
    }
  }

  &__personal_info {
    margin-right: 8px;

    &>p {
      @include body5;
    }

    &>span {
      @include body6;
      color: var(--grey-primary)
    }
  }
}

.therapist_menu_block {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 16px;

  &__personal_info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__item {
      width: 100%;
      display: flex;

      &>span {
        @include body6;
        width: 50%;
        color: var(--grey-primary);
      }

      &>p {
        @include body5;
      }
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--btn-violet-secondary);

    &__submit_btn {
      @extend .purpleBtn;
      width: 174px;
    }

    &__details_btn {
      @extend .pinkBtn;
      width: 174px;
    }
  }
}

.second_footer {
  width: 100%;
  border-radius: 12px;
  padding: 16px 24px;
  background-color: var(--base-white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px -10px 9px 0px rgba(0, 0, 0, 0.02);

  &__submit_btn {
    @extend .purpleBtn;
    width: 166px
  }

  &__submit_btn_disabled {
    @extend .purpleBtn;
    background-color: var(--btn-violet-disabled) !important;
    cursor: default !important;
    width: 166px
  }

  &__reset_btn {
    @extend .noBorderBtn;
    width: 166px
  }

  &__reset_btn_disabled {
    @extend .noBorderBtn;
    color: var(--btn-violet-disabled) !important;
    cursor: default !important;
    width: 166px
  }
}

.country_container {
  display: flex;
  align-items: center;
  gap: 5px;

  &>span {
    width: max-content !important;
    font-family: var(--poppins-medium-500) !important;
    line-height: 20px !important;
    font-size: 14px !important;
  }
}


//BASE
.purpleBtn {
  @include body5;
  color: var(--base-white);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 50px;
  background-color: var(--btn-violet-primary);
  cursor: pointer;
}

.pinkBtn {
  @include button1;
  color: var(--btn-pink-primary);
  border: 1px solid var(--btn-pink-primary);
  border-radius: 50px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.noBorderBtn {
  @include button2;
  padding: 10px 20px;
  color: var(--btn-violet-primary);
  cursor: pointer;
}

.cursor {
  cursor: pointer;
}