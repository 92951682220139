@import '../../../theme/scss/mixins.scss';

.table_container {
  margin-top: 12px;
  box-shadow: 2px 2px 12px 0px rgba(157, 157, 209, 0.13);
  overflow-x: scroll;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

th {
  gap: 6px;
  padding: 16px;
  flex: 1;

  width: fit-content;
  max-width: 150px;
  // overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

tbody {
  background-color: #ffffff;
}

.sort_icon {
  cursor: pointer;
}

.header_item {
  display: flex;
  gap: 6px;
  align-items: center;
  font-size: 12px;
  color: #8e7f97;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
}

thead {
  width: 100%;
  background: #fdfcff;
  display: table-header-group;
  text-align: left;
}

tr {
  border-bottom: 1px solid var(--purple200-background);
}

td {
  vertical-align: middle;
  height: 72px;
  padding: 16px;

  width: fit-content;
  max-width: 150px;
  // overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table_item {
  font-family: var(--poppins-regular);
  font-size: 14px;
  line-height: 20px;
  color: var(--text-gray);
  text-align: left;
  min-width: 100px;
  // overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &__no_info {
    color: var(--text-secondary) !important;
    @include subtext2;
  }
}

.table_item_collapsed_child {
  @extend .table_item;
  background-color: #f6f6fba9;
}

.table_item_collapsed_parent {
  @extend .table_item;
  background-color: #885fa533;
}

.applier_container {
  display: flex;
  gap: 12px;
  align-items: center;

  &__initials {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    background: var(--purple200-background);
    color: var(--type-purpure100-color);

    & > img {
      width: 100%;
      height: 100%;
      object-fit: fill;
      border-radius: 6px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 2;
    width: 100%;

    &__name {
      font-size: 14px;
      color: var(--black900-color);
      display: inline-block;
      // overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &__active_link {
        font-size: 14px;
        color: var(--black900-color);
      }

      &__active_link:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .no_info {
      @include body6;
      color: var(--btn-violet-primary);
    }

    & span:last-child {
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.phone_container {
  display: flex;
  gap: 8px;
  align-items: center;
  font-family: var(--poppins-regular);
}

.status_container {
  width: max-content;
  height: 23px;

  padding: 4px 12px 4px 12px;
  border-radius: 50px;

  font-weight: 500;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.action_btn_container {
  display: flex;
}

.action_btn {
  width: 145px;
  height: 34px;
  font-weight: 500;
  padding: 7px 12px 7px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  cursor: pointer;
  margin-left: 8px;
}

.second_action_btn {
  background-color: #8450a0;
  border: none;
  border-radius: 50px;
  padding: 7px 12px;
  cursor: pointer;
}

.country_container {
  display: flex;
  align-items: center;
  gap: 5px;

  & > span {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.therapists_container {
  display: flex;
  align-items: center;
  gap: 8px;
  overflow-x: scroll;
  padding-bottom: 5px;

  &__photo {
    & > img {
      width: 24px;
      height: 24px;
      border-radius: 6px;
      object-fit: fill;
    }
  }

  &__initials {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    background: var(--purple200-background);
    color: var(--type-purpure100-color);
  }
}

.advisor_container {
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;

  &__photo {
    & > img {
      width: 24px;
      height: 24px;
      border-radius: 6px;
      object-fit: fill;
    }
  }

  &__initials {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    background: var(--purple200-background);
    color: var(--type-purpure100-color);
  }
}

.selectors_container {
  width: fit-content;
  display: flex;
  gap: 8px;
  align-items: center;

  .form_item {
    width: 70%;

    &__selector > div > input {
      color: var(--grey-primary);
    }
  }

  .small_field {
    width: 30%;
  }

  .btn {
    padding: 10px 20px;
    border: none;
    background-color: var(--btn-violet-primary);
    color: var(--base-white);
    @include body2;
    border-radius: 50px;
    margin-top: 25px;
    cursor: pointer;
  }
}

.calcom_connection {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__icon {
    width: 25px;
    height: 25px;
  }

  &__icon_menu {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
}

.action_menu {
  width: 166px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 12px;
  padding: 12px;

  position: absolute;
  right: 0;
  top: 32px;
  z-index: 100 !important;

  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    width: 100%;
    height: 36px;
    padding: 8px;

    border-bottom: 1px solid var(--base-50);

    font-family: var(--poppins-regular) !important;
    font-size: 14px;
    line-height: 20px;

    color: #626262;

    cursor: pointer;
  }

  &__delete {
    @include body6;
    color: var(--error-primary) !important;
    border-bottom: none !important;
  }

  .accept {
    border-bottom: 1px solid #eeeeee;
  }

  p:hover {
    background: #f6f0fc;
    border-radius: 8px;
  }
}

.tharepistDatailBtn {
  width: 100%;
  padding: 7px 12px;
  background: none;
  border-radius: 50px;
  border: 1px solid #d89b95;
  color: #d89b95;
  font-family: var(--poppins-medium-500);
  cursor: pointer;
}

.therapistActionBtnsContainer {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;

  &__accept {
    padding: 7px 12px;
    border-radius: 50px;
    width: 84px;
    border: none;
    background: #8450a0;
    color: #ffffff;
    font-family: var(--poppins-medium-500);
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
  }

  &__decline {
    border: none;
    background: none;
    color: #8450a0;
    font-family: var(--poppins-medium-500);
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
  }
}

.therapistJoinBtn {
  padding: 7px 12px;
  border-radius: 50px;
  border: none;
  width: fit-content;
  background: #8450a0;
  color: #ffffff;
  font-family: var(--poppins-medium-500);
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}

.therapistGoToProfile_container {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;

  & > svg {
    width: 24px;
    height: 24px;
  }

  &__btn {
    color: #8450a0;
    font-size: 16px;
    font-family: var(--poppins-medium-500);
  }
}

.start_meeting_container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.start_meeting {
  min-width: 44px;
  min-height: 34px;
  border-radius: 50px;
  background-color: var(--btn-violet-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

//BASE
.vertical_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.purpleBtn {
  @include body5;
  color: var(--base-white);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 15px;
  border-radius: 50px;
  background-color: var(--btn-violet-primary);
  cursor: pointer;
}

.purpleBtn_disabled {
  @include body2;
  padding: 10px 20px;
  background-color: var(--btn-violet-disabled);
  color: var(--base-white);
  border-radius: 50px;
}

.pinkBtn {
  @include subtext2;
  color: var(--btn-pink-primary);
  border: 1px solid var(--btn-pink-primary);
  border-radius: 50px;
  padding: 7px 12px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.noBorderBtn {
  @include button2;
  color: var(--btn-violet-primary);
  cursor: pointer;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

.parents_container:hover {
  text-decoration: underline;
  cursor: pointer;
}

.text_ellepsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.admin_meetings_btns {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > svg {
    width: 30px;
    height: 30px;
    fill: green;
    cursor: pointer;
  }
}
